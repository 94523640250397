import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TextField from "@mui/material/TextField";
import { Box, Stack, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export const SerialNumberFileUpdate = ({
  url,
  setImportExel,
  selectedFile,
  setNotification,
  setSelectedFile,
  handleFileChange,
  setErrorRows,
  keycloak,
  handleClickOpen
}) => {
  const [isLoading, setisLoading] = React.useState(false);
  const [vendor, setVendor] = React.useState("");
  const [deliveryDate, setDeliveryDate] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");

  const handleImport = (event) => {
    event.preventDefault();

    if (selectedFile) {
      setisLoading(true);
      const formdata = new FormData();
      formdata.append("file", selectedFile);
      formdata.append("vendor", vendor);
      formdata.append("deliveryDate", deliveryDate);
      formdata.append("orderNumber", orderNumber);

      if (selectedFile) {
        fetch(`${url}/import_file`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: keycloak?.token,
          },
        })
          .then((response) => {
            if (response.status !== 200)
              throw new Error("Ошибка при загрузке, проверьте файл");
            if (response.status === 401) {
              keycloak?.logout();
            }
            return response.json();
          })
          .then((data) => {
            if (data["status"] === "Success")
              setNotification({
                open: true,
                type: "alert",
                message: "Успешно загружено",
                severity: "success",
              });
            if (data.error) {
              setNotification({
                open: true,
                type: "alert",
                message: "Найдены ошибки",
                severity: "error",
              });
              setErrorRows(data.error);
            }
          })
          .catch((error) => {
            setNotification({
              open: true,
              type: "alert",
              message: error.message,
              severity: "error",
            });
            handleClickOpen();
          })
          .finally(() => {
            setisLoading(false);
            setVendor("");
            setSelectedFile(null);
            setImportExel(false);
            setDeliveryDate("");
            setOrderNumber("");
          });
      }
    }
  };

  const handleViewFile = () => {
    if (selectedFile) {
      window.open(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <Box sx={{width:"100%",}}>
    <form onSubmit={handleImport}>
      <Stack direction="column" spacing={"10px"}  >
      <Stack direction="column" spacing={"10px"}>
      <Stack direction="column" spacing={"10px"} width={"100%"}><Typography
          sx={{
            fontSize: 16,
            fontWeight: "600",
            lineHeight: "24px",
            letterSpacing: 0.4,
            width:"300px"
          }}
        
        >
          Добавить серийный номер
        </Typography>
      <Stack direction="row" spacing={"10px"}  width={"100%"} >
        
        <TextField
          onChange={(e) => setVendor(e.target.value)}
          value={vendor}
          required
          id="outlined-basic"
          label="Поставщик"
          variant="outlined"
          size="small"
          fullWidth
        />
        <TextField
          onChange={(e) => setDeliveryDate(e.target.value)}
          value={deliveryDate}
          required
          id="outlined-basic"
          label="Дата поставки"
          variant="outlined"
          size="small"
          fullWidth
        />
        <TextField
          onChange={(e) => setOrderNumber(e.target.value)}
          value={orderNumber}
          required
          id="outlined-basic"
          label="Номер заказа"
          variant="outlined"
          size="small"
          fullWidth
        />

        </Stack>
        <Stack direction="row" alignItems={"space-between"} width={"100%"} spacing={"10px"} >
          <Stack width={"100%"}  justifyContent={"space-between"} direction={"row"} spacing={"10px"} >
          <Stack width={"100%"}   direction={"row"}  alignItems={"center"} spacing={"10px"}  >
        <input
          accept=".xlsx"
          id="upload-button"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
          value={""}
        />
        <label htmlFor="upload-button">
          <Button
          
            startIcon={<FileUploadIcon />}
            variant="text"
            component="span"
            sx={{ boxShadow: "none", width: "200px", alignSelf: "flex-start" }}
          >
            Заменить файл
          </Button>
        </label>  {selectedFile && (
          <Stack
            color="text.secondary"
            direction="row"
            spacing="10px"
            justifyContent="flex-end"
          >
            <Stack alignItems="center" direction="row" gap="10px">
              <AttachFileIcon
                sx={{ transform: "rotate(45deg)", fontSize: 17 }}
              />
              <Typography
                sx={{ fontSize: 14, maxWidth: "152px" }}
                noWrap={true}
              >
                {selectedFile.name}
              </Typography>
            </Stack>

            <Button
              color="inherit"
              startIcon={<RemoveRedEyeIcon />}
              variant="text"
              onClick={handleViewFile}
            >
              Посмотреть
            </Button>
          </Stack>
        )}   </Stack> <Stack alignItems={"center"}  width="500px" direction="row"  sx={{ alignSelf:"flex-end",}}
        justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                setImportExel(false);
                setSelectedFile(null);
              }}
              fullWidth
              variant="outlined"
            >
              Назад
            </Button>
            <Button
              type="submit"
              sx={{ boxShadow: "none", ml:"10px" }}
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={"1rem"} /> : "Импорт таблицы"}
            </Button></Stack>
        </Stack>  </Stack> 
       
      </Stack> 
    
        </Stack>
      </Stack>
    </form>
    </Box>
  );
};
