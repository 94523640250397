import React, { useState } from "react";
import { Box } from "@mui/material";
import TgBot from "./Pages/TgBot";
import SerialNumber from "./Pages/SerialNumber";

import Header from "./Components/Header";
import Login from "./Pages/Login";
import { usePocket } from "./hooks/usePocket";
import Container from "@mui/material/Container";
import { Route, Routes, redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

const Routing = () => {
  const { token, user } = usePocket();
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated === false) {
    return <>{redirect(keycloak.createLoginUrl())}</>;
  }

  return (
    <>

    
      {keycloak.authenticated ? (
        <>
          <Header />
          <Container disableGutters>
            <Routes>
            {keycloak.realmAccess.roles.some((role) =>
                ["TgBot", "TgBotSmkt"].includes(role)
              ) && <Route path="*"element={<TgBot />} />}
              {keycloak.realmAccess.roles.some((role) =>
                ["SerialNumberAdmin", "SerialNumberOperator"].includes(role)
              ) && <Route path="SerialNumber" element={<SerialNumber />} />}
            </Routes>
          </Container>
        </>
      ) : (
        <Box>
          <Routes>
            <Route path="*" element={<LinearProgress color="secondary" />} />
          </Routes>
        </Box>
      )}
    </>
  );
};

export default Routing;
