import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import template from "../../../files/template.xlsx";

export const AddMenu = ({
  manual,
  setManual,
  importExel,
  handleFileChange,
}) => {
  const handleDownload = () => {
    const fileUrl = template;
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = "Шаблон «Серийные номера».xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {!manual && !importExel && (
        <Stack spacing="10px" width="100%" direction="row">
          <Button
            sx={{ boxShadow: "none", width:"354px" }}
            onClick={() => setManual(true)}
            variant="contained"

          >
            Ввод вручную
          </Button>
          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            sx={{
              ".MuiButtonGroup-middleButton": {
                borderRadius: "4px 0px 0px 4px",
              },
            }}
          >
            <input
              accept=".xlsx"
              id="upload-button"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
              value={""}
            />
            <label htmlFor="upload-button">
              <Button
                startIcon={<FileUploadIcon />}
                variant="outlined"
                component="span"
                sx={{ boxShadow: "none", width: "300px" }}
              >
                Импорт таблицы
              </Button>
            </label>

            <Tooltip title="Скачать шаблон">
              <Button>
                <FileDownloadIcon onClick={handleDownload} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Stack>
      )}
    </>
  );
};
