import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Divider from "@mui/material/Divider";
import { SerialNumberManualAdd } from "./ManualAdd/SerialNumberManualAdd";
import { SerialNumberFileUpdate } from "./FileUpdate/SerialNumberFileUpdate";
import { AddMenu } from "./AddMenu";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export const SerialNumberAdd = ({
  notification,
  url,
  setPage,
  isLoading,
  keycloak,
  setNotification,
}) => {
  const [manual, setManual] = React.useState(false);
  const [importExel, setImportExel] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const [errorRows, setErrorRows] = React.useState();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = file.name;

      if (file && fileName.endsWith(".xlsx")) {
        setSelectedFile(file);
        setImportExel(true);
        setNotification(null);
      } else {
        setNotification({
          open: true,
          type: "Snackbar",
          message: "Пожалуйста, выберите файл с расширением .xlsx",
          severity: "error",
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
 
  };

  return (
    <Stack>
      <Stack
      width={"100%"}    
       direction={"row"} alignItems={"center"}
        sx={{
          py: "15px",
          px:"25px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(33, 33, 33, 1)"
              : "rgba(242, 242, 242, 1)",
      
          borderRadius: "4px",
        }}
      >
      {  !manual && !importExel && <Typography
          sx={{
            fontSize: 16,
            fontWeight: "600",
            lineHeight: "24px",
            letterSpacing: 0.4,
            width:"300px"
          }}
        
        >
          Добавить серийный номер
        </Typography>}
        <AddMenu
          manual={manual}
          setManual={setManual}
          importExel={importExel}
          handleFileChange={handleFileChange}
        />
        {manual && (
          <SerialNumberManualAdd
            setManual={setManual}
            url={url}
            setPage={setPage}
            isLoading={isLoading}
            setImportExel={setImportExel}
            keycloak={keycloak}
            notification={notification}
            setNotification={setNotification}
          />
        )}
        {importExel && (
          <SerialNumberFileUpdate
          handleClickOpen={handleClickOpen}
            url={url}
            setImportExel={setImportExel}
            selectedFile={selectedFile}
            setNotification={setNotification}
            setSelectedFile={setSelectedFile}
            handleFileChange={handleFileChange}
            setErrorRows={setErrorRows}
            keycloak={keycloak}
          />
        )}
      </Stack>
      <Dialog fullWidth onClose={handleClose} open={open} sx={{ p: "25px" }}>
      {notification?.severity === "error" &&
        notification?.type !== "Snackbar" && (
          <Stack
            sx={{
              bgcolor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(37, 33, 33, 1)"
                  : "#F1E5E5",
              p: "25px",
              border: "1px solid #FF4343",
              borderRadius: "4px",
       
            }}
            overflow={"auto"}
        
          >
            <Typography
              display={"flex"}
              justifyContent={"space-between"}
              color={"#FF4343"}
              fontSize={20}
              fontWeight={700}
              sx={{ pb: "20px" }}
            >
              Найдены ошибки
              <Button
                size="small"
                onClick={() => {
                  setNotification(false);
                  handleClose()
                }}
                sx={{ color: "#707070" }}
                endIcon={<CloseIcon />}

              >
                Закрыть
              </Button>
            </Typography>
            
            <Stack>
              {errorRows &&
                errorRows.map((row, index) => (
             
                    <Stack width="100%" key={row.row_number}>
                    <Stack spacing={"20px"} direction="row">
                      <Typography
                        maxWidth={"114px"}
                        noWrap={true}
                        fontSize={14}
                        fontWeight={600}
                      >
                        Строка {row.row_number}
                      </Typography>
                      {row.serial_number && (
                        <Typography
                          maxWidth={"114px"}
                          noWrap={true}
                          fontSize={14}
                          fontWeight={600}
                        >
                          {row.serial_number}
                        </Typography>
                      )}
                      {row.name_technique && (
                        <Typography
                          maxWidth={"114px"}
                          noWrap={true}
                          fontSize={14}
                          fontWeight={600}
                        >
                          {row.name_technique}
                        </Typography>
                      )}
                      {row.category && (
                        <Typography
                          maxWidth={"114px"}
                          noWrap={true}
                          fontSize={14}
                          fontWeight={600}
                        >
                          {row.category}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="row">
                      <Typography noWrap={true} fontSize={14}>
                        {!row.serial_number && "Нет серийного номера"}
                      </Typography>
                      <Typography noWrap={true} fontSize={14}>
                        {!row.name_technique && "Нет наименования"}
                      </Typography>
                      <Typography noWrap={true} fontSize={14}>
                        {!row.category && "Нет категории"}
                      </Typography>
                    </Stack>
                    {index !== errorRows.length - 1 && (
                      <Divider sx={{ my: "10px" }} />
                    )}
                  </Stack>
            
                ))}
            </Stack>
          </Stack>
        )}      </Dialog>
      {notification?.severity === "success" &&
        notification?.type !== "Snackbar" && (
          <Stack
            sx={{
              bgcolor: "rgba(27, 156, 24, 0.1)",
              px: "25px",
              border: "1px solid rgba(50, 158, 47, 1)",
              borderRadius: "4px",
              mt: "10px",
              py: "10px",
            }}
          >
            <Stack spacing="10px" direction="row" width="100%">
              <CheckCircleOutlineIcon sx={{ color: "#4ED04B" }} />
              <Typography
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                color={"#4ED04B"}
              >
                Успешно загружено
                <Button
                  size="small"
                  onClick={() => {
                    setNotification(false);
                  }}
                  sx={{ color: "#707070" }}
                  endIcon={<CloseIcon />}
                >
                  Закрыть
                </Button>
              </Typography>
            </Stack>
          </Stack>
        )}
    </Stack>
  );
};
