import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { usePocket } from "../hooks/usePocket";
import { ruRU } from "@mui/material/locale";

export const withTheme = (Component) => () => {
  const { mode } = usePocket();
  const theme = createTheme(
    {
      typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
      },
      breakpoints: {
        values: {
          xs: 0,
          mobile: 400,
          sm: 600,
          md: 900,
          lg: 1280,
          xl: 1536,
        },
      },

      palette: {
        mode: mode,
        primary: {
          main: "#1B9C18",
        },
        secondary: {
          main: "rgba(134, 84, 204, 1)",
        },

        ...(mode === "light"
          ? {
              background: {
                default: "#E6E6E6",
                paper: "#ffffff",
              },
            }
          : {
              background: {
                paper: "#212121",
                default: "#171717",
              },
            }),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
              boxShadow: "none",
              ":hover": { boxShadow: "none" },
            },
          },
        },
      },
    },
    ruRU
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Component />
    </ThemeProvider>
  );
};
