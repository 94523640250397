import React from "react";
import { SWRConfig } from "swr";
import { useKeycloak } from "@react-keycloak/web";

export const withSwr = (Component) => () => {
  const { keycloak } = useKeycloak();

  return (
    <SWRConfig
      value={{
        fetcher: (resource, init, headers) =>
          fetch(resource, {
            ...init,
            headers: { Authorization: keycloak?.token },
          })
            .then((res) => {
              if (!res.ok) {
                throw new Error(res.status);
              }
              return res.json();
            })
            .catch((error) => {
              if (error.message === "401") {
                keycloak?.logout();
              }
            }),
      }}
    >
      <Component />
    </SWRConfig>
  );
};
