import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Stack, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FilterSerialNumber } from "./FilterSerialNumber/FilterSerialNumber";
import { styled } from "@mui/material/styles";
import { socket } from "../../socket";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

export const TableSerialNumber = ({
  data,
  isLoading,
  page,
  setPage,
  textFields,
  setTextFields,
  isLoadingMore,
  mutate,
  isWithoutAdd,
  url,
  keycloak,
  setNotification,
}) => {
  const isAdmin = keycloak.realmAccess.roles.some((role) =>
    ["SerialNumberAdmin"].includes(role)
  );

  const [isUpdatingRow, setIsUpdatingRow] = useState(false);
  const columns = [
    {
      field: "name_technique",
      width: isWithoutAdd ? 335 : 450,
      editable: !isUpdatingRow && isAdmin,

      sortable: false,

      renderHeader: () => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "700",
            bgcolor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(55, 55, 55, 1)"
                : "rgba(225, 225, 225, 1)",
            minWidth: isWithoutAdd ? 325 : 410,
            height: "37px",
            width: "100%",
            borderRadius: "4px",
            pt: "8px",
          }}
          px="8px"
        >
          Наименование товара
        </Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            bgcolor={"#1B9C181A"}
            spacing={"10px"}
            borderRadius={"4px"}
            height="40px"
            px="8px"
          >
            <Tooltip
              title={
                isAdmin && row.name_technique?.length > 20
                  ? row.name_technique
                  : !isAdmin && row.name_technique?.length > 40
                  ? row.name_technique
                  : ""
              }
            >
              <Typography noWrap={true} fontSize="14px">
                {row.name_technique}
              </Typography>
            </Tooltip>
            <IconButton
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#9fa49f" : "#0000004D",
              }}
              onClick={() => {
                navigator.clipboard.writeText(row.name_technique);
              }}
              size="small"
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },

    {
      field: "serial_number",
      sortable: false,
      width: isWithoutAdd ? 195 : 310,
      editable: !isUpdatingRow && isAdmin,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "700",
            bgcolor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(55, 55, 55, 1)"
                : "rgba(225, 225, 225, 1)",
            minWidth: isWithoutAdd ? 185 : 300,

            height: "37px",
            borderRadius: "4px",
            pt: "8px",
          }}
          px="8px"
        >
          Серийный номер
        </Typography>
      ),

      renderCell: ({ row }) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            spacing={"10px"}
            height="40px"
            px="8px"
          >
            <Tooltip
              title={
                isAdmin && row.serial_number?.length > 14
                  ? row.serial_number
                  : !isAdmin && row.serial_number?.length > 24
                  ? row.serial_number
                  : ""
              }
            >
              <Typography noWrap={true} fontSize="14px">
                {row.serial_number}
              </Typography>
            </Tooltip>
            <IconButton
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#9fa49f" : "#0000004D",
              }}
              onClick={() => {
                navigator.clipboard.writeText(row.serial_number);
              }}
              size="small"
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "vendor",
      editable: !isUpdatingRow && isAdmin,
      width: isWithoutAdd ? 170 : 243,
      sortable: false,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "700",
            bgcolor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(55, 55, 55, 1)"
                : "rgba(225, 225, 225, 1)",
            minWidth: isWithoutAdd ? 160 : 233,
            height: "37px",
            borderRadius: "4px",
            pt: "8px",
          }}
          px="8px"
        >
          Поставщик
        </Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={
              isAdmin && row.vendor?.length > 20
                ? row.vendor
                : !isAdmin && row.vendor?.length > 30
                ? row.vendor
                : ""
            }
          >
            <Typography px="8px" noWrap={true} fontSize="14px">
              {row.vendor}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "category",
      editable: !isUpdatingRow && isAdmin,
      minWidth: isWithoutAdd ? 160 : 227,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "700",
            bgcolor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(55, 55, 55, 1)"
                : "rgba(225, 225, 225, 1)",
            minWidth: isWithoutAdd ? 150 : 217,
            height: "37px",
            borderRadius: "4px",
            pt: "8px",
          }}
          px="8px"
        >
          Категория
        </Typography>
      ),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={
              isAdmin && row.category?.length > 19
                ? row.category
                : !isAdmin && row.category?.length > 30
                ? row.category
                : ""
            }
          >
            <Typography px="8px" noWrap={true} fontSize="14px">
              {row.category}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "deliveryDate",
      editable: !isUpdatingRow && isAdmin,
      minWidth: isWithoutAdd ? 160 : 227,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "700",
            bgcolor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(55, 55, 55, 1)"
                : "rgba(225, 225, 225, 1)",
            minWidth: isWithoutAdd ? 150 : 217,
            height: "37px",
            borderRadius: "4px",
            pt: "8px",
          }}
          px="8px"
        >
          Дата поставки
        </Typography>
      ),
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={
              isAdmin && row.deliveryDate?.length > 19
                ? row.deliveryDate
                : !isAdmin && row.deliveryDate?.length > 30
                ? row.deliveryDate
                : ""
            }
          >
            <Typography px="8px" noWrap={true} fontSize="14px">
              {row.deliveryDate}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "orderNumber",
      editable: !isUpdatingRow && isAdmin,
      minWidth: isWithoutAdd ? 160 : 27,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "700",
            bgcolor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(55, 55, 55, 1)"
                : "rgba(225, 225, 225, 1)",
            minWidth: isWithoutAdd ? 150 : 217,
            height: "37px",
            borderRadius: "4px",
            pt: "8px",
          }}
          px="8px"
        >
          Номер заказа
        </Typography>
      ),
      sortable: false,

      renderCell: ({ row }) => {
        const isHovered = row.id === hoveredRow;
        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              title={
                isAdmin && row.orderNumber?.length > 11
                  ? row.orderNumber
                  : !isAdmin && row.orderNumber?.length > 30
                  ? row.orderNumber
                  : ""
              }
            >
              <Typography px="8px" noWrap={true} fontSize="14px">
                {row.orderNumber}
              </Typography>
            </Tooltip>
            {/* не для всех сделать  */}
            <Tooltip title="Удалить запись">
              <IconButton
                style={{
                  visibility: isHovered ? "visible" : "hidden",
                }}
                // onClick={() => handleRowDelete(row.id)}
                onClick={handleClickOpen}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const [hoveredRow, setHoveredRow] = useState(false);

  useEffect(() => {
    socket.on("message", () => mutate());

    return () => {
      socket.off("message", () => mutate());
    };
  }, []);

  const pageLimits = {
    min: 1,
    max: data?.total_pages,
  };
  const handlePageChange = (newPage) => {
    setPage(+newPage);
  };

  const [lastTotalPages, setLastTotalPages] = useState(data?.total_pages);
  useEffect(() => {
    if (data?.total_pages) {
      setLastTotalPages(data?.total_pages);
    }
  }, [data?.total_pages]);

  const pageCount = `${page} из ${
    data?.total_pages ? data?.total_pages : lastTotalPages
  }`;

  function CustomPagination() {
    return (
      (data?.total_pages > 1 ||
        isLoadingMore ||
        (page > 1 && !lastTotalPages !== undefined)) && (
        <Stack width="100%">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt="10px"
            spacing={"2px"}
          >
            <Typography minWidth={104}> {pageCount}</Typography>

            <IconButton
              onClick={() => setPage(page - 1)}
              aria-label="previous"
              disabled={page === 1}
              size="small"
            >
              <NavigateBeforeIcon />
            </IconButton>
            <TextField
              sx={{
                ".MuiOutlinedInput-input": {
                  p: 0,
                  mx: "10px",
                  width: "24px",

                  textAlign: "center",
                },
              }}
              onChange={(e) => {
                let value = +e.target.value;
                if (Number.isNaN(value)) {
                  value = pageLimits.min;
                }
                if (value > pageLimits.max) value = pageLimits.max;
                if (value < pageLimits.min) value = pageLimits.min;
                handlePageChange(value);
              }}
              value={page}
            />
            <IconButton
              onClick={() => setPage(page + 1)}
              aria-label="next"
              disabled={
                page === data?.total_pages ||
                data?.total_pages === 0 ||
                page === lastTotalPages
              }
              size="small"
            >
              <NavigateNextIcon />
            </IconButton>
          </Stack>
        </Stack>
      )
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Typography color={"text.secondary"} sx={{ mt: 1 }}>
          Нет данных соответствующих запросу
        </Typography>
      </StyledGridOverlay>
    );
  }

  const handleRowDelete = (id) => {
    fetch(`${url}/delete_serial_number`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: keycloak?.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok (status ${response.status})`
          );
        }
        return response.json();
      })
      .finally(() => {
        setNotification({
          open: true,
          type: "Snackbar",
          message: "Запись удалена",
          severity: "success",
        });
      })
      .catch((error) => {
        setNotification({
          open: true,
          type: "Snackbar",
          message: "Не удалось удалить запись",
          severity: "error",
        });
        if (error.message === "Network response was not ok (status 401)") {
          keycloak?.logout();
        }
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useFakeMutation = () => {
    return React.useCallback(
      (id) => () => {
        if (id)
          setTimeout(() => {
            processRowUpdate();
          });
      },
      []
    );
  };

  const mutateRow = useFakeMutation();
  const processRowUpdate = React.useCallback(
    (newRow) => {
      if (!isUpdatingRow) {
        setIsUpdatingRow(true);

        const response = mutateRow(newRow);
        fetch(url + "/update_serial_number", {
          method: "PATCH",
          body: JSON.stringify(newRow),
          headers: {
            "Content-Type": "application/json",
            Authorization: keycloak?.token,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Network response was not ok (status ${response.status})`
              );
            }
            return response.json(response);
          })
          .then(() => {
            setNotification({
              open: true,
              type: "Snackbar",
              message: "Успешно изменено",
              severity: "success",
            });
          })
          .catch((error) => {
            setNotification({
              open: true,
              type: "Snackbar",
              message: error.message,
              severity: "error",
            });
            if (error.message === "Network response was not ok (status 401)") {
              keycloak?.logout();
            }
          })
          .finally(() => {
            setIsUpdatingRow(false);
          });

        return response;
      }
    },
    [isUpdatingRow, keycloak, mutateRow, setNotification, url]
  );

  return (
    <Box>
      <Box
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(33, 33, 33, 1)"
              : "rgba(242, 242, 242, 1)",
          p: "24px",
          width: "100%", // поменять
          height: 809,
       
        }}
        borderRadius="4px"
      >
        <FilterSerialNumber
          textFields={textFields}
          setTextFields={setTextFields}
          setPage={setPage}
          url={url}
        />
        <DataGrid
          processRowUpdate={processRowUpdate}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          sx={{
            ".MuiDataGrid-cell": { p: "5px", border: "none" },
            ".MuiDataGrid-columnHeader": { p: "5px" },
            ".MuiDataGrid-columnHeaders": { border: "none" },

            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            ".MuiDataGrid-columnHeader:focus-within": { outline: 0 },
            ".MuiDataGrid-cell:focus": { outline: 0 },
            px: "7px",
            border: (theme) =>
              theme.palette.mode === "dark"
                ? "1px solid rgba(242, 242, 242, 0.2)"
                : "1px solid rgba(23, 23, 23, 0.2)",
            height: "647px",
            width: "100%",
            overflowY: "auto",
          }}
          loading={isLoading}
          rows={data?.items || []}
          columns={columns}
          hideFooterSelectedRowCount
          hideFooter
          onRowClick={(row) => {
            if (
              keycloak.realmAccess.roles.some((role) =>
                ["SerialNumberAdmin"].includes(role)
              )
            ) {
              setHoveredRow(row.id);
            }
          }}
        />

        <CustomPagination />
      </Box>
      <React.Fragment>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="dialog-title">
            {"Вы действительно хотите удалить эту запись?"}
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <Button
              sx={{ boxShadow: "none" }}
              onClick={() => {
                handleRowDelete(hoveredRow);
                handleClose();
              }}
              variant="contained"
            >
              Удалить
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Box>
  );
};
