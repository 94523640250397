import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";
const keycloak = new Keycloak({
  url: "https://auth.smkt.pro",
  realm: "megamarket",
  clientId: "megamarket",
});
export const withKeycloak = (Component) => () =>
  (
    <ReactKeycloakProvider
      initOptions={{
        onLoad: "login-required",
        flow: "standard",
      }}
      authClient={keycloak}
    >
      <Component />
    </ReactKeycloakProvider>
  );
