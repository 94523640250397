import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import PocketBase from "pocketbase";

const BASE_URL = "https://ds.smkt.pro";
const PocketContext = createContext({});

!localStorage.getItem("mode") && localStorage.setItem("mode", "light");

export const PocketProvider = ({ children }) => {
  const pb = useMemo(() => new PocketBase(BASE_URL), []);
  pb.autoCancellation(false);
  const [token, setToken] = useState(pb.authStore.token);
  const [user, setUser] = useState(pb.authStore.model);
  const [mode, setMode] = useState(localStorage.getItem("mode"));

  useEffect(() => {
    return pb.authStore.onChange((token, model) => {
      setToken(token);
      setUser(model);
      setMode(localStorage.getItem("mode"));
    });
  }, []);

  // const register = useCallback(async(username, password) =>{
  // return await pb
  // .collection("users")
  // .create({username, password, passwordConfirm: password })
  // }, [])

  const login = useCallback(async (username, password) => {
    return await pb.collection("users").authWithPassword(username, password);
  }, []);
  const changeTheme = () => {
    return setMode((prev) => {
      prev === "light"
        ? localStorage.setItem("mode", "dark")
        : localStorage.setItem("mode", "light");
      return prev === "light" ? "dark" : "light";
    });
  };

  const logout = useCallback(async (username, password) => {
    return await pb.authStore.clear();
  }, []);

  return (
    <PocketContext.Provider
      value={{ user, login, logout, token, pb, mode, changeTheme }}
    >
      {children}
    </PocketContext.Provider>
  );
};

export const usePocket = () => useContext(PocketContext);
