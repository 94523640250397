import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

export const SerialNumberManualAdd = ({
  url,
  setManual,
  setImportExel,
  keycloak,
  notification,
  setNotification,
}) => {
  const [form, setForm] = useState({
    serial_number: "",
    name_technique: "",
    category: "",
    vendor: "",
    deliveryDate: "",
    orderNumber: "",
  });

  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({
      open: false,
      type: "Snackbar",
      message: "",
      severity: "",
    });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const addData = (e) => {
    e.preventDefault();
    setForm({
      serial_number: "",
      name_technique: "",
      category: "",
      vendor: "",
      deliveryDate: "",
      orderNumber: "",
    });
    setLoading(true);

    fetch(url + "/add_serial_number", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Authorization: keycloak?.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok (status ${response.status})`
          );
        }
        return response.json(response);
      })
      .then((data) => {
        if (data.status !== "double")
          setNotification({
            open: true,
            type: "Snackbar",
            message: "Успешно добавлено",
            severity: "success",
          });
        else
          setNotification({
            open: true,
            type: "Snackbar",
            message: "Запись уже существует",
            severity: "warning",
          });
      })
      .catch((error) => {
        setNotification({
          open: true,
          type: "Snackbar",
          message: error.message,
          severity: "error",
        });
        if (error.message === "Network response was not ok (status 401)") {
          keycloak?.logout();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  return (
    <Stack width={"100%"}>
      <form onSubmit={addData}>
        <Stack sx={{ width:"100%"}} direction="column" spacing={"10px"} justifyContent={"space-around"}>
      <Typography
          sx={{
            fontSize: 16,
            fontWeight: "600",
            lineHeight: "24px",
            letterSpacing: 0.4,
            width:"300px"
          }}
        
        >
          Добавить серийный номер
        </Typography>
        <Stack direction="row" spacing={"10px"}>
          <Stack width={"100%"} direction="column" spacing={"10px"}>
          <TextField
          fullWidth
            onChange={handleFormChange}
            required
            id={"serial_number"}
            label={"Серийный номер"}
            size="small"
            value={form.serial_number}
          />
          <TextField
          fullWidth
            onChange={handleFormChange}
            required
            id={"name_technique"}
            label={"Наименование товара"}
            size="small"
            value={form.name_technique}
          />  </Stack>
               <Stack width={"100%"} direction="column" spacing={"10px"}>
          <TextField
          fullWidth
            onChange={handleFormChange}
            required
            id={"category"}
            label={"Категория"}
            size="small"
            value={form.category}
          />
          <TextField 
            onChange={handleFormChange}
            required
            id={"vendor"}
            label={"Поставщик"}
            size="small"
            value={form.vendor}
          /></Stack>
                 <Stack width={"100%"} direction="column" spacing={"10px"}>
          <TextField
            onChange={handleFormChange}
            required
            id={"deliveryDate"}
            label={"Дата поставки"}
            size="small"
            value={form.deliveryDate}
          />
          <TextField
            onChange={handleFormChange}
            required
            id={"orderNumber"}
            label={"Номер заказа"}
            size="small"
            value={form.orderNumber}
          /></Stack>

      
          </Stack>    
          <Stack spacing="10px" width="100%" direction="row" justifyContent="flex-end">
            <Button
              onClick={() => {
                setManual(false);
                setImportExel(false);
              }}
              sx={{ width: "131px" }}
              fullWidth
              variant="outlined"
            >
              Назад
            </Button>
            <Button
              sx={{ width: "210px", boxShadow: "none" }}
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress size={"1rem"} /> : "Добавить"}
            </Button>  </Stack>
        </Stack>
      </form>
      <Snackbar
        open={notification?.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={notification?.severity}
          sx={{ width: "100%" }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
