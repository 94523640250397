import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { EmployeesList } from "../Components/EmployeesList";
import { EmployeesAdd } from "../Components/EmployeesAdd";
import { EmployeesInfo } from "../Components/EmployeesInfo";
import useSWR from "swr";
import CircularProgress from "@mui/material/CircularProgress";
import { usePocket } from "../hooks/usePocket";
import { useKeycloak } from "@react-keycloak/web";
import BuddyInfo from "../Components/BuddyInfo";

const TgBot = () => {
  const { keycloak } = useKeycloak();

  const [dpInfo, setDpInfo] = useState(null);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentsAnchorEl, setStudentsAnchorEl] = useState(null);

  const url = process.env.REACT_APP_BASE_URL_TG;

  function useSupervisors() {
    const { data, error, isLoading } = useSWR(url + `/get_supervisor`);

    return {
      supervisor: data,
      isLoadingSupervisor: isLoading,
      isError: error,
    };
  }

  function useStudent() {
    const { data, error, isLoading, mutate } = useSWR(url + `/get_list`);

    return {
      student: data?.students,
      isLoadingStudent: isLoading,
      isError: error,
      studentMutate: mutate,
    };
  }
  function useBuddy() {
    const { data, error, isLoading, mutate } = useSWR(url + `/get_buddy`);

    return {
      buddy: data,
      isLoadingBuddy: isLoading,
      isError: error,
      buddyMutate: mutate,
    };
  }

  const { supervisor, isLoadingSupervisor } = useSupervisors();
  const { student, isLoadingStudent, studentMutate } = useStudent();
  const { buddy, isLoadingBuddy, buddyMutate } = useBuddy();

  useEffect(() => {
    if (
      !isLoadingSupervisor &&
      !isLoadingStudent &&
      !isLoadingBuddy &&
      student &&
      supervisor &&
      buddy
    )
      setData([...student, ...supervisor, ...buddy]);
  }, [
    isLoadingSupervisor,
    isLoadingStudent,
    student,
    supervisor,
    buddy,
    isLoadingBuddy,
  ]);

  function useSearchStudent() {
    const { data, error, isLoading, mutate } = useSWR(
      url + `/search_students?id=${dpInfo?.tg_id}`
    );

    return {
      searchStudents: data?.students,
      isLoadingSearchStudents: isLoading,
      isError: error,
      studentsSearchMutate: mutate,
    };
  }

  const {
    searchStudents,
    isLoadingSearchStudents,
    isError,
    studentsSearchMutate,
  } = useSearchStudent();

  function useSearchBuddy() {
    const { data, error, isLoading, mutate } = useSWR(
      url + `/search_buddy?tg_id=${dpInfo?.tg_id}`
    );

    return {
      searchBuddy: data,
      isLoadingSearchBuddy: isLoading,
      isErrorBuddy: error,
      buddySearchMutate: mutate,
    };
  }

  const { searchBuddy, isLoadingSearchBuddy, buddySearchMutate, isErrorBuddy } =
    useSearchBuddy();

  console.log(dpInfo);

  if (isLoadingStudent && isLoadingSupervisor && isLoadingBuddy)
    return (
      <CircularProgress
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          ml: "-3rem",
          mt: "-3rem",
        }}
        size={"4rem"}
      />
    );

  const handleDelete = (item) => {
    fetch(url + "/delete_line", {
      method: "DELETE",
      body: JSON.stringify({
        id: item.id,
        type: item.type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: keycloak?.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok (status ${response.status})`
          );
        } else {
          studentMutate();
        }
        return response.json();
      })
      .catch((error) => {
        if (error.message === "Сетевой запрос не удался (статус 401)") {
          keycloak?.logout();
        }
      })
      .finally(() => {
        studentMutate();
        studentsSearchMutate();
        setAnchorEl(null);
        setStudentsAnchorEl(null);
      });
  };

  return (
    <Box sx={{ display: "flex", gap: "40px", marginTop: "30px" }}>
      <Box maxWidth={510}>
        <EmployeesAdd
          studentMutate={studentMutate}
          setData={setData}
          supervisor={supervisor || []}
          url={url}
          keycloak={keycloak}
          studentsSearchMutate={studentsSearchMutate}
          buddyMutate={buddyMutate}
          buddy={buddy || []}
        />

        {dpInfo && dpInfo.type !== "buddy" ? (
          <EmployeesInfo
            dpInfo={dpInfo || []}
            keycloak={keycloak}
            studentMutate={studentMutate}
            searchStudents={searchStudents || []}
            searchBuddy={searchBuddy || []}
            studentsSearchMutate={studentsSearchMutate}
            isLoadingSearchStudents={isLoadingSearchStudents}
            handleDelete={handleDelete}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        ) : (
          <Box></Box>
        )}
        {dpInfo?.type === "buddy" ? (
          <BuddyInfo searchBuddy={searchBuddy} />
        ) : (
          <Box></Box>
        )}
        {!dpInfo?.type && (
          <Box
            sx={{
              minHeight: 415,
              marginTop: "20px",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(33, 33, 33, 1)"
                  : "rgba(242, 242, 242, 1)",
              borderRadius: "4px",
              p: "10px",
              px: "20px",
            }}
          ></Box>
        )}
      </Box>

      <EmployeesList
        data={data || []}
        setInfo={setDpInfo}
        handleDelete={handleDelete}
        studentsAnchorEl={studentsAnchorEl}
        setStudentsAnchorEl={setStudentsAnchorEl}
      />
    </Box>
  );
};

export default TgBot;
