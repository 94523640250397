import React, { useState, useEffect } from "react";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const EmployeesList = ({
  setInfo,
  data,
  handleDelete,
  studentsAnchorEl,
  setStudentsAnchorEl,
}) => {
  const [value, setValue] = React.useState("one");

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleChange = (event, newValue) => {
    setSearchTerm("");
    setValue(newValue);
    switch (newValue) {
      case "one":
        setSortedData(data);
        break;
      case "two":
        setSortedData(
          data.filter((item) => !item.hasOwnProperty("fullname_supervisor"))
        );
        break;
      case "three":
        setSortedData(
          data.filter((item) => item.hasOwnProperty("fullname_supervisor"))
        );
        break;
      case "four":
        setSortedData(
          data.filter((item) => item.hasOwnProperty("fullname_buddy"))
        );
        break;
      default:
        break;
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState({ id: 0, name_student: "" });

  const handleChangers = (event) => {
    !(value === "one") && setValue("one");
    setSearchTerm(event.target.value);
    setSortedData(
      data.filter(
        (item) =>
          item.name_student?.includes(event.target.value) ||
          item.fullname_supervisor?.includes(event.target.value)
      )
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? "rgba(33, 33, 33, 1)"
            : "rgba(242, 242, 242, 1)",
        borderRadius: "4px",
        px: "36px",
        py: "20px",
        width: "100%",
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="one"
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "none",
                  fontWeight: "500",
                  gap: "10px",
                  fontSize: "14px",
                }}
              >
                <GroupsIcon /> Все
              </Box>
            }
          />
          <Tab
            value="two"
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  textTransform: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                <PeopleIcon /> Cотрудники
              </Box>
            }
          />
          <Tab
            value="three"
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  textTransform: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                <PersonIcon /> Руководители
              </Box>
            }
          />
          <Tab
            value="four"
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  textTransform: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                <Diversity3Icon /> Бадди
              </Box>
            }
          />
        </Tabs>
      </Box>
      <TextField
        size="small"
        id="search"
        type="search"
        label="Поиск по имени"
        value={searchTerm}
        onChange={handleChangers}
        fullWidth
        sx={{ marginTop: "10px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <List
        sx={{
          gap: "10px",
          p: "10px",
          px: "20px",
          border: (theme) =>
            theme.palette.mode === "dark"
              ? "1px solid rgba(242, 242, 242, 0.2)"
              : "1px solid rgba(23, 23, 23, 0.2)",

          borderRadius: "4px",
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "67vh",
          overflow: "auto",
        }}
      >
        {sortedData &&
          sortedData.map((item, index) => (
            <ListItem
              disablePadding
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(37, 37, 37, 1)"
                    : "rgba(225, 225, 225, 1)",
                borderRadius: "4px",
                p: 0,
              }}
              key={item.id}
              secondaryAction={
                item.type === "student" && (
                  <IconButton
                    onClick={(e) => {
                      setSelectedItem({
                        id: item.id,
                        type: item.type,
                      });
                      setAnchorEl(e.currentTarget);
                    }}
                    edge="end"
                    aria-label="delete"
                    sx={{
                      display: selectedItem.id === item.id ? "flex" : "none",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
              onMouseEnter={() =>
                setSelectedItem({
                  id: item.id,
                  type: item.type,
                })
              }
            >
              <ListItemButton
                onClick={(e) => {
                  setInfo(item);
                }}
                key={item.created}
              >
                <ListItemIcon>
                  {item.type === "student" && <PeopleIcon />}
                  {item.type === "buddy" && <Diversity3Icon />}
                  {item.type === "supervisor" && <PersonIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    item.name_student ||
                    item.fullname_supervisor ||
                    item.fullname_buddy
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Typography sx={{ p: 2 }}>Удалить ученика</Typography>
          <Divider />
          <Stack spacing={1} sx={{ p: 1 }} direction={"row"}>
            <Button
              fullWidth
              onClick={() => {
                handleDelete(selectedItem);
                setAnchorEl(null);
              }}
              variant="contained"
            >
              Да
            </Button>
            <Button
              variant="outlined"
              onClick={() => setAnchorEl(null)}
              fullWidth
            >
              Нет
            </Button>
          </Stack>
        </Popover>
      </List>
    </Box>
  );
};
