import React, { useState } from "react";
import { TableSerialNumber } from "../Components/TableSerialNumber/TableSerialNumber";
import { SerialNumberAdd } from "../Components/TableSerialNumber/TableIAdd/SerialNumberAdd";
import { Stack } from "@mui/material";
import useSWR from "swr";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useKeycloak } from "@react-keycloak/web";

const SerialNumber = () => {
  const url = process.env.REACT_APP_BASE_URL;
  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    severity: "",
    type: "",
  });

  const { keycloak } = useKeycloak();

  const [page, setPage] = React.useState(1);
  const [textFields, setTextFields] = useState([
    {
      url: "name_technique",
      value: "",
      title: "Наименование товара",
    },
    {
      url: "serial_number",
      value: "",
      title: "Серийный номер",
    },
    { url: "vendor", value: "", title: "Поставщик" },
    { url: "category", value: "", title: "Категория" },
    { url: "deliveryDate", value: "", title: "Дата поставки" },
    { url: "orderNumber", value: "", title: "Номер заказа" },
  ]);

  let fetchUrl = `${url}/get_serial_number?${`${textFields
    .map((field) => (field.value !== "" ? `&${field.url}=${field.value}` : ""))
    .join("")}`}&pageNumber=${page}`;

  const { data, mutate, error, isLoading } = useSWR(fetchUrl);

  const isLoadingMore = textFields.every((field) => field.value === "");

  if (error)
    return (
      <Alert sx={{ mt: "30px" }} variant="outlined" severity="error">
        Ошибка при загрузке
      </Alert>
    );

  if (isLoading && page === 1 && isLoadingMore)
    return (
      <CircularProgress
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          ml: "-3rem",
          mt: "-3rem",
        }}
        size={"4rem"}
      />
    );

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mt: "20px",
          mx: 1,
       
        }}
      >
        {keycloak.realmAccess.roles.some((role) =>
          ["SerialNumberAdmin"].includes(role)
        ) && (
          <SerialNumberAdd
            mutate={mutate}
            page={page}
            setPage={setPage}
            data={data}
            url={url}
            isLoading={isLoading}
            keycloak={keycloak}
            notification={notification}
            setNotification={setNotification}
          />
        )}

        <TableSerialNumber
          setPage={setPage}
          data={data}
          url={url}
          page={page}
          textFields={textFields}
          setTextFields={setTextFields}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          mutate={mutate}
          isWithoutAdd={true}
          keycloak={keycloak}
          setNotification={setNotification}
        />
      </Stack>
    </>
  );
};
export default SerialNumber;
