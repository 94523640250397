import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TelegramIcon from "@mui/icons-material/Telegram";
import ArticleIcon from "@mui/icons-material/Article";
import { usePocket } from "../hooks/usePocket";
import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useKeycloak } from "@react-keycloak/web";
import newYear from "../images/newYear.svg";
import newYearBlack from "../images/newYearBlack.svg";

export default function Header() {
  const navigate = useNavigate();
  const { changeTheme, mode } = usePocket();
  const { logout, user } = usePocket();
  const { keycloak } = useKeycloak();

  const pages = [
    {
      name: "TG_bot",
      link: "TG_bot",
      icon: <TelegramIcon />,
      access: ["TgBot", "TgBotSmkt"],
    },
    {
      name: "Серийные номера",
      link: "serialNumber",
      icon: <ArticleIcon />,
      access: ["SerialNumberAdmin", "SerialNumberOperator"],
    },
  ];

  const headerTitle = { serialNumber: "Серийные номера", TG_bot: "TG_bot" };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const list = () => (
    <Box sx={{ width: 250 }}>
      <List
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 1,

          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </List>
      <Divider />
      <List>
        {pages.map(
          (page, index) =>
            keycloak?.realmAccess?.roles.some((role) =>
              page.access.includes(role)
            ) && (
              <ListItem
                onClick={() => {
                  navigate(`/${page.link}`);
                  handleDrawerClose();
                }}
                key={index}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.name} href={page.link} />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(33, 33, 33, 1)"
              : "rgba(242, 242, 242, 1)",
          py: "19px",
        }}
        elevation={0}
      >
        <Container disableGutters maxWidth="lg">
          <Toolbar disableGutters variant="">
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <IconButton
                size="large"
                edge="start"
                color="default"
                aria-label="menu"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 0.5) "
                      : "rgba(23, 23, 23, 0.5",
                  mr: 1,
                }}
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>

              <Typography
                variant="h6"
                component="div"
                lineHeight={1}
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                }}
              >
                Data
                <Typography
                  variant="body"
                  component="div"
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                  }}
                >
                  Support
                </Typography>
              </Typography>
            </Stack>
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(242, 242, 242, 1)"
                    : "rgba(23, 23, 23, 1)",
                textAlign: "center",

                flexGrow: 1,
              }}
            >
              {window.location.pathname.split("/")[1]
                ? headerTitle[window.location.pathname.split("/")[1]]
                : "TG_bot"}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Switch
                checked={mode === "dark" ? true : false}
                onChange={() => changeTheme()}
                color="default"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    margin: 1,
                    padding: 0,

                    "&.Mui-checked": {
                      transform: "translateX(22px)",
                      "& .MuiSwitch-thumb:before": {
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="18" viewBox="0 0 23 19"><path fill="${encodeURIComponent(
                          "rgba(23, 23, 23, 1)"
                        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
                      },
                    },
                  },
                  "& .MuiSwitch-thumb": {
                    "&:before": {
                      content: "''",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      top: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                        "rgba(23, 23, 23, 0.5)"
                      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
                    },
                  },
                }}
              />

              <IconButton
                size="large"
                edge="start"
                color="default"
                aria-label="menu"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 0.5) "
                      : "rgba(23, 23, 23, 0.5",
                }}
                onClick={() => keycloak.logout()}
              >
                <ExitToAppIcon />
              </IconButton>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
        {list()}
      </Drawer>
    </>
  );
}
