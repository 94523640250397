import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { usePocket } from "../hooks/usePocket";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { Button, Divider, Stack } from "@mui/material";
import useSWR from "swr";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Popover from "@mui/material/Popover";
import PersonIcon from "@mui/icons-material/Person";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PeopleIcon from "@mui/icons-material/People";

export const EmployeesInfo = ({
  dpInfo,
  keycloak,
  studentMutate,
  searchStudents,
  studentsSearchMutate,
  isLoadingSearchStudents,
  handleDelete,
  anchorEl,
  setAnchorEl,
  searchBuddy,
}) => {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [selectedItem, setSelectedItem] = useState();

  console.log(searchBuddy);

  return (
    <Box
      sx={{
        minHeight: 415,
        marginTop: "20px",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? "rgba(33, 33, 33, 1)"
            : "rgba(242, 242, 242, 1)",
        borderRadius: "4px",
        p: "10px",
        px: "20px",
      }}
    >
      {dpInfo && dpInfo.hasOwnProperty("fullname_supervisor") ? (
        <>
          <Typography
            sx={{
              color: "rgba(27, 156, 24, 1)",
              fontWeight: "600",
              fontSize: "16px",
              mb: 2,
            }}
            variant="h6"
            component="h2"
          >
            <Stack direction="row">
              <PersonIcon sx={{ mr: 0.5 }} color="inherit" />
              {dpInfo.fullname_supervisor}
            </Stack>
          </Typography>
          <Typography
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(242, 242, 242, 1)"
                  : "rgba(23, 23, 23, 1)",
              fontWeight: "600",
              fontSize: "14px",
            }}
            variant="h6"
            component="h2"
          >
            Ученики
          </Typography>

          {searchStudents && (
            <List
              sx={{
                gap: "10px",
                p: "10px",
                px: "20px",
                border: "1px solid rgba(23, 23, 23, 0.2)",
                borderRadius: "4px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                maxHeight: "33vh",
                overflow: "auto",
              }}
              secondaryaction={
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              }
            >
              {searchStudents?.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(38, 41, 38, 1)"
                        : "rgba(220, 236, 225, 1)",
                    borderRadius: "4px",
                    p: 0,
                  }}
                  secondaryAction={
                    <IconButton
                      onClick={(e) => {
                        handleClick(e);
                        setSelectedItem({
                          id: item.id,
                          type: item.type,
                        });
                      }}
                      edge="end"
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  <ListItemButton key={item.created}>
                    {item.name_student}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>Удалить ученика</Typography>
            <Divider />
            <Stack spacing={1} sx={{ p: 1 }} direction={"row"}>
              <Button
                fullWidth
                onClick={() => {
                  handleDelete(selectedItem);
                  setAnchorEl(null);
                }}
                variant="contained"
              >
                Да
              </Button>
              <Button
                variant="outlined"
                onClick={() => setAnchorEl(null)}
                fullWidth
              >
                Нет
              </Button>
            </Stack>
          </Popover>
        </>
      ) : (
        dpInfo && (
          <>
            <Typography
              sx={{
                color:
                  dpInfo.status === "true"
                    ? "rgba(27, 156, 24, 1)"
                    : "rgba(255, 54, 54, 1)",
                fontWeight: "600",
                fontSize: "16px",
              }}
              variant="h6"
              component="h2"
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {dpInfo.type === "student" && (
                  <PeopleIcon sx={{ mr: 0.5 }} color="inherit" />
                )}
                {dpInfo.type === "buddy" && (
                  <Diversity3Icon sx={{ mr: 0.5 }} color="inherit" />
                )}
                {dpInfo.name_student}
              </Stack>

              {dpInfo.status === "false" && (
                <NotificationsOffIcon color="rgba(255, 54, 54, 1)" />
              )}
            </Typography>
            <Typography
              sx={{ display: "flex", gap: "10px", mt: "10px" }}
              variant="body2"
              component="span"
            >
              {dpInfo.platform && (
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 1)"
                        : "rgba(23, 23, 23, 1)",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.platform}
                </Box>
              )}
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 0.5)"
                      : "rgba(23, 23, 23, 0.5",
                  fontWeight: 600,
                }}
              >
                {dpInfo.data_student}
              </Box>
            </Typography>
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                  mt: "10px",
                }}
              >
                Руководитель:
              </Box>

              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.name_supervisor}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                  mt: "10px",
                }}
              >
                Бадди:
              </Box>

              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.name_buddy ? dpInfo.name_buddy : "Нет бадди"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 1
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_1 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_1 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 2
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_2 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_2 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 3
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_3 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_3 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 4
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_4 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_4 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 5
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_5 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_5 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 6
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_6 ? "Отправлено" : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />{" "}
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 6
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_6 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_6 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>{" "}
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                СМС -> 7
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_7 === "true"
                    ? "Отправлено"
                    : dpInfo.sms_7 === "false"
                    ? "Ошибка отправки"
                    : "Нет информации"}
                </Box>
              </Typography>
            </Typography>
            <Divider
              sx={{
                my: "9px",
                borderColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(23, 23, 23, 0.7)"
                    : "rgba(0, 0, 0, 0.12)",
              }}
            />
            <Typography variant="h6" component="h2">
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 1)"
                      : "rgba(23, 23, 23, 1)",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Завершил испытательный срок?
              </Box>
              <Typography
                sx={{ display: "flex", gap: "10px" }}
                variant="body2"
                component="span"
              >
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 0.7)"
                        : "rgba(23, 23, 23, 0.7)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {dpInfo.sms_4 ? "Да" : "Нет"}
                </Box>
              </Typography>
            </Typography>
            {dpInfo.failed && (
              <Typography variant="h6" component="h2">
                <Divider
                  sx={{
                    my: "9px",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(23, 23, 23, 0.7)"
                        : "rgba(0, 0, 0, 0.12)",
                  }}
                />
                <Box
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(242, 242, 242, 1)"
                        : "rgba(23, 23, 23, 1)",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Причина увольнения
                </Box>
                <Typography
                  sx={{ display: "flex", gap: "10px" }}
                  variant="body2"
                  component="span"
                >
                  <Box
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "dark"
                          ? "rgba(242, 242, 242, 0.7)"
                          : "rgba(23, 23, 23, 0.7)",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {dpInfo.failed}
                  </Box>
                </Typography>
              </Typography>
            )}
          </>
        )
      )}
    </Box>
  );
};
