import React from "react";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { Stack, Typography, Divider } from "@mui/material";
import Diversity3Icon from "@mui/icons-material/Diversity3";

const BuddyInfo = ({ searchBuddy }) => {
  return (
    <Box
      sx={{
        minHeight: 415,
        marginTop: "20px",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? "rgba(33, 33, 33, 1)"
            : "rgba(242, 242, 242, 1)",
        borderRadius: "4px",
        p: "10px",
        px: "20px",
      }}
    >
      {searchBuddy ? (
        <>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              mb: 2,
            }}
            variant="h6"
            component="h2"
          >
            <Stack direction="row">
              <Diversity3Icon sx={{ mr: 0.5 }} color="inherit" />
              {searchBuddy.fullname_buddy}
            </Stack>
          </Typography>
          <Divider
            sx={{
              my: "9px",
              borderColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(23, 23, 23, 0.7)"
                  : "rgba(0, 0, 0, 0.12)",
            }}
          />
          <Typography variant="h6" component="h2">
            <Box
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(242, 242, 242, 1)"
                    : "rgba(23, 23, 23, 1)",
                fontWeight: "600",
                fontSize: "14px",
                mt: "10px",
              }}
            >
              TG ID:
            </Box>

            <Typography
              sx={{ display: "flex", gap: "10px" }}
              variant="body2"
              component="span"
            >
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 0.7)"
                      : "rgba(23, 23, 23, 0.7)",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {searchBuddy.tg_id}
              </Box>
            </Typography>
          </Typography>
          <Divider
            sx={{
              my: "9px",
              borderColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(23, 23, 23, 0.7)"
                  : "rgba(0, 0, 0, 0.12)",
            }}
          />
          <Typography variant="h6" component="h2">
            <Box
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(242, 242, 242, 1)"
                    : "rgba(23, 23, 23, 1)",
                fontWeight: "600",
                fontSize: "14px",
                mt: "10px",
              }}
            >
              TG логин:
            </Box>

            <Typography
              sx={{ display: "flex", gap: "10px" }}
              variant="body2"
              component="span"
            >
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 0.7)"
                      : "rgba(23, 23, 23, 0.7)",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {searchBuddy.tg_login}
              </Box>
            </Typography>
          </Typography>
          <Divider
            sx={{
              my: "9px",
              borderColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(23, 23, 23, 0.7)"
                  : "rgba(0, 0, 0, 0.12)",
            }}
          />
          <Typography variant="h6" component="h2">
            <Box
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(242, 242, 242, 1)"
                    : "rgba(23, 23, 23, 1)",
                fontWeight: "600",
                fontSize: "14px",
                mt: "10px",
              }}
            >
              Имя в TG:
            </Box>

            <Typography
              sx={{ display: "flex", gap: "10px" }}
              variant="body2"
              component="span"
            >
              <Box
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(242, 242, 242, 0.7)"
                      : "rgba(23, 23, 23, 0.7)",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {searchBuddy.tg_name}
              </Box>
            </Typography>
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default BuddyInfo;
