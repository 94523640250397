import compose from "compose-function";

import { withTheme } from "./with-theme";
import { withPocket } from "./with-pocket";
import { withRouter } from "./with-routing";
import { withDayjs } from "./with-dayjs";
import { withKeycloak } from "./with-keycloak";
import { withSwr } from "./with-swr";

export const withProviders = compose(
  withRouter,
  withPocket,
  withDayjs,
  withTheme,
  withKeycloak,
  withSwr
);
