import React, { useState } from "react";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const EmployeesAdd = ({
  supervisor,
  url,
  studentMutate,
  keycloak,
  studentsSearchMutate,
  buddyMutate,
  buddy,
}) => {
  const [ruk, setRuk] = useState("");
  const [date, setDate] = useState(null);
  const [name, setName] = useState("");
  const [buddySelect, setBuddySelect] = useState("");
  const [platform, setPlatform] = useState("");

  const handleChanger = (event) => {
    setRuk(event.target.value);
  };

  const handleChangerBuddy = (event) => {
    setBuddySelect(event.target.value);
  };

  const handleChangersetPlatform = (event) => {
    setPlatform(event.target.value);
  };

  const handleDownload = () => {
    fetch(`${url}/export_file`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: keycloak?.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok (status ${response.status})`
          );
        } else {
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Отчёт TG_bot.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (error.message === "Network response was not ok (status 401)") {
          keycloak?.logout();
        }
      });
  };

  const handleSubmit = () => {
    fetch(`${url}/add_student`, {
      method: "POST",
      body: JSON.stringify({
        tg_id_supervisor: ruk.tg_id,
        name_student: name,
        data_student: dayjs(date).format("DD.MM.YYYY"),
        name_supervisor: ruk.fullname_supervisor,
        status: "true",
        name_buddy: buddySelect.fullname_buddy,
        tg_id_buddy: buddySelect.tg_id,
        platform: platform,
      }),

      headers: {
        "Content-Type": "application/json",
        Authorization: keycloak?.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok (status ${response.status})`
          );
        } else {
          studentMutate();
          studentsSearchMutate();
        }
        return response.json();
      })
      .catch((error) => {
        if (error.message === "Network response was not ok (status 401)") {
          keycloak?.logout();
        }
      })
      .finally(() => {
        setRuk("");
        setName("");
        setBuddySelect("");
        setDate(null);
        studentMutate();
        studentsSearchMutate();
        buddyMutate();
        setPlatform("");
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? "rgba(33, 33, 33, 1)"
            : "rgba(242, 242, 242, 1)",
        borderRadius: "4px",
        p: "10px",
        px: "20px",
        width: 510,
      }}
    >
      <Box sx={{ marginBottom: "10px", marginTop: "20px" }}>
        <Box
          sx={{
            color: "rgba(23, 23, 23, 1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // gap: "40px",
          }}
        >
          <Box
            sx={{
              fontWeight: "600",
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(242, 242, 242, 1)"
                  : "rgba(23, 23, 23, 1)",
            }}
          >
            Добавить участника
          </Box>

          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              color: "rgba(27, 156, 24, 1)",
              fontWeight: 700,
              textTransform: "none",
              fontSize: "16px",
            }}
            onClick={handleDownload}
          >
            <FileDownloadIcon />
            Скачать отчётность
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <TextField
          required
          select
          id="demo-simple-select"
          value={ruk}
          label="Руководитель"
          onChange={handleChanger}
        >
          <MenuItem value="">
            <em>Выбери руководителя</em>
          </MenuItem>
          {supervisor?.map((item) => (
            <MenuItem value={item} key={item.id}>
              {item.fullname_supervisor}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          value={buddySelect}
          label="Бадди"
          onChange={handleChangerBuddy}
        >
          <MenuItem value="">
            <em>Выбери Бадди</em>
          </MenuItem>
          {buddy?.map((item) => (
            <MenuItem value={item} key={item.id}>
              {item.fullname_buddy}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
          id="outlined-basic"
          label="ФИО ученика"
          variant="outlined"
        />
        <DatePicker
          label="ЧЧ.ММ.ГГГГ *"
          localeText={{
            toolbarTitle: "ЧЧ.ММ.ГГГГ *",
            fieldDayPlaceholder: () => "ДД",
            fieldMonthPlaceholder: () => "ММ",
            fieldYearPlaceholder: () => "ГГГГ *",
          }}
          minDate={dayjs.utc().add(1, "day")}
          value={date}
          onChange={(e) => setDate(dayjs(e))}
          sx={{
            "& input.Mui-disabled": {
              color: "green",
              WebkitTextFillColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(242, 242, 242, 1)"
                  : "rgba(23, 23, 23, 1)",
            },
          }}
          slotProps={{
            textField: {
              disabled: true,
            },
          }}
        />
        <TextField
          select
          required
          value={platform}
          label="Платформа"
          onChange={handleChangersetPlatform}
        >
          <MenuItem value="">
            <em>Выбери платформу</em>
          </MenuItem>

          <MenuItem value="Мегамаркет">Мегамаркет</MenuItem>
          {/* <MenuItem value="СберЛогистика">СберЛогистика</MenuItem> */}
        </TextField>

        <Button
          onClick={() => handleSubmit()}
          sx={{
            backgroundColor: "rgba(27, 156, 24, 1)",
            marginBottom: "23px",
          }}
          variant="contained"
          color="success"
          disabled={!name || !date || !ruk || !platform}
        >
          Добавить
        </Button>
      </Box>
    </Box>
  );
};
