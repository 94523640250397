import React from "react";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useSWR from "swr";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const FilterSerialNumber = ({
  textFields,
  setTextFields,
  setPage,
  url,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [form, setForm] = useState([
    {
      id: "nameTechnique",
      FetchDataValue: "name_technique",
      value: "",

      label: "Наименование товара",
    },
    {
      id: "serialNumber",
      FetchDataValue: "serial_number",
      value: "",

      label: "Серийный номер",
    },
  ]);

  const handleFormChange = (e) => {
    setForm((prevForm) =>
      prevForm.map((field) =>
        field.id === e.target.id ? { ...field, value: e.target.value } : field
      )
    );
  };

  const handleAddToFilter = () => {
    const updatedFields = [...textFields];

    form.forEach((field) => {
      const fieldIndex = updatedFields.findIndex(
        (filter) => filter.url === field.FetchDataValue
      );

      if (fieldIndex !== -1 && field.value.trim() !== "") {
        updatedFields[fieldIndex].value = field.value;
      }
    });

    setPage(1);
    setTextFields(updatedFields);
    setForm((prevForm) =>
      prevForm.map((field) =>
        field.value.trim() !== "" ? { ...field, value: "" } : field
      )
    );
  };

  const handleDeleteFilter = (url) => {
    const updatedFields = [...textFields];
    const fieldIndex = updatedFields.findIndex((filter) => filter.url === url);

    if (fieldIndex !== -1) {
      updatedFields[fieldIndex].value = "";
    }

    setPage(1);
    setTextFields(updatedFields);
  };

  const [vendorOpen, setVendorOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [deliveryDateOpen, setDeliveryDateOpen] = useState(false);
  const [orderNumberOpen, setOrderNumberOpen] = useState(false);

  function useCategory() {
    const { data, error, isLoading } = useSWR(
      categoryOpen ? `${url}/get_category` : null
    );

    return {
      category: data?.categories,
      isLoadingCategory: isLoading,
      isError: error,
    };
  }

  const { category, isLoadingCategory } = useCategory();

  function useVendor() {
    const { data, error, isLoading } = useSWR(
      vendorOpen ? `${url}/get_vendor` : null
    );
    return {
      vendor: data?.vendors,
      isLoadingVendor: isLoading,
      isError: error,
    };
  }

  const { deliveryDate, isLoadingDeliveryDate } = useDeliveryDate();

  function useDeliveryDate() {
    const { data, error, isLoading } = useSWR(
      deliveryDateOpen ? `${url}/get_deliveryDate` : null
    );
    return {
      deliveryDate: data?.deliveryDate,
      isLoadingDeliveryDate: isLoading,
      isError: error,
    };
  }

  const { orderNumber, isLoadingOrderNumber } = useOrderNumber();

  function useOrderNumber() {
    const { data, error, isLoading } = useSWR(
      orderNumberOpen ? `${url}/get_orderNumber` : null
    );
    return {
      orderNumber: data?.orderNumber,
      isLoadingOrderNumber: isLoading,
      isError: error,
    };
  }

  const { vendor, isLoadingVendor } = useVendor();

  const handleAutocompleteChange = (event, value, fieldId) => {
    if (value !== null) {
      const updatedFields = [...textFields];

      const fieldIndex = updatedFields.findIndex(
        (filter) => filter.url === fieldId
      );

      if (fieldIndex !== -1) {
        updatedFields[fieldIndex].value = value;
      } else {
        updatedFields.push({
          url: fieldId,
          value: value,
        });
      }
      setPage(1);
      setTextFields(updatedFields);
    }
  };

  return (
    <Stack mb={"10px"} direction="row" alignItems="center" spacing="10px">
     

      <Button
        startIcon={<FilterAltIcon />}
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        sx={{ width: "170px" }}
      >
        Фильтры
      </Button> <Stack  
        spacing={"10px"}
        direction="row"
        overflow={"auto"}
        maxWidth={"786px"}
      >
    
        {textFields.map(
          (filter) =>
            filter.value && (
              <Chip
                sx={{ maxWidth: "200px" }}
                onDelete={() => handleDeleteFilter(filter.url)}
                size="small"
                key={filter.url}
                label={filter.value}
                avatar={<Avatar>{filter.title.slice(0, 1)}</Avatar>}
              />
            )
        )}
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack p="10px" spacing="10px" direction="column">
          <Stack direction={"row"} spacing={"10px"}>
            {form.map((field) => (
              <form
                key={field.id}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddToFilter(field.FetchDataValue, field.value);
                }}
              >
                <Stack direction="row">
                  <FormControl size="small">
                    <InputLabel htmlFor={`outlined-adornment-${field.label}`}>
                      {field.label}
                    </InputLabel>
                    <OutlinedInput
                      value={field.value}
                      autoComplete="off"
                      id={field.id}
                      onChange={handleFormChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!field.value}
                            edge="end"
                            type="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label={field.label}
                    />
                  </FormControl>
                </Stack>
              </form>
            ))}
          </Stack>
          <Stack direction={"row"} spacing={"10px"}>
            <Autocomplete
              fullWidth
              loading={isLoadingVendor}
              open={vendorOpen}
              onOpen={() => {
                setVendorOpen(true);
              }}
              onClose={() => {
                setVendorOpen(false);
              }}
              size="small"
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, "vendor");
              }}
              id="vendor"
              options={vendor || []}
              renderInput={(params) => (
                <TextField {...params} label="Поставщик" />
              )}
            />
            <Autocomplete
              fullWidth
              open={categoryOpen}
              onOpen={() => {
                setCategoryOpen(true);
              }}
              onClose={() => {
                setCategoryOpen(false);
              }}
              loading={isLoadingCategory}
              size="small"
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, "category");
              }}
              id="category"
              options={category || []}
              renderInput={(params) => (
                <TextField {...params} label="Категория" />
              )}
            />
          </Stack>
          <Stack direction={"row"} spacing={"10px"}>
            <Autocomplete
              fullWidth
              open={deliveryDateOpen}
              onOpen={() => {
                setDeliveryDateOpen(true);
              }}
              onClose={() => {
                setDeliveryDateOpen(false);
              }}
              loading={isLoadingDeliveryDate}
              size="small"
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, "deliveryDate");
              }}
              id="deliveryDate"
              options={deliveryDate || []}
              renderInput={(params) => (
                <TextField {...params} label="Дата поставки" />
              )}
            />
            <Autocomplete
              fullWidth
              open={orderNumberOpen}
              onOpen={() => {
                setOrderNumberOpen(true);
              }}
              onClose={() => {
                setOrderNumberOpen(false);
              }}
              loading={isLoadingOrderNumber}
              size="small"
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, "orderNumber");
              }}
              id="orderNumber"
              options={orderNumber || []}
              renderInput={(params) => (
                <TextField {...params} label="Номер заказа" />
              )}
            />
          </Stack>

          <Button
            sx={{ maxWidth: "284px" }}
            onClick={handleClose}
            variant="outlined"
          >
            Назад
          </Button>
        </Stack>
      </Popover>
    </Stack>
  );
};
